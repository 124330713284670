export default [ { id: 'pQaZrS4STdA', start: '' },
  { id: 'rvpkRXp6qbc', start: '' },
  { id: '_dWJVHIE9S8', start: '' },
  { id: 'UpIMFker8Mg', start: '' },
  { id: '2m8YACFJlMg', start: '' },
  { id: 'w2-e8eERg_c', start: '' },
  { id: 'P4q4rspgtD4', start: '' },
  { id: 'AOhQZlNptJY', start: '' },
  { id: 'NmRgQcMPlQo', start: '' },
  { id: 'YJSehRlU34w', start: '' },
];

// THIS FILE IS FORMATTED LIKE THIS TO MATCH LINE NUMBERS TO VIDEO UI
